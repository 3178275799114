import {
  useState,
  useEffect,
  Fragment,
  KeyboardEvent,
  SetStateAction,
  ChangeEvent,
} from "react";
import { Topbar } from "../../../component/Topbar";
import { useHistory } from "react-router-dom";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Tabs,
  Tab,
} from "@material-ui/core/";
import { Box, Typography } from "@mui/material";
import ThemesAutocomplete from "../../../component/ThemesAutocomplete";
import ThemesGenre from "../../../component/ThemesGenre";
import ThemesType from "../../../component/ThemesType";
import Schools from "../../../component/Schools";
import { apiSecure } from "domain/api";
import moment from "moment";
import { Link } from "react-router-dom";
import { Container, Content, Texts } from "./styles";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { addListener } from "process";
import React from "react";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";

function NewEssay() {
  let history = useHistory();
  // const classes : any = useStyles();
  const [type, setType] = useState({});
  const [request, setRequest] = useState(false);
  const [gender, setGender] = useState({});
  const [theme, setTheme] = useState([]);
  const [school, setSchool] = useState([]);
  const [owner, setOwner] = useState(false);
  const [classrooms, setClassrooms] = useState([]);
  const [selectedClassroom, setSelectedClassrooms] = useState([]);
  const qs = require("qs");
  const [minWords, setMinWords] = useState(150);
  const [maxWords, setMaxWords] = useState(300);
  const [endDate, setEndDate] = useState(
    moment().add(30, "day").format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [send, setSend] = useState(false);

  const [essayDetails, setEssayDetails] = useState("");
  const [errorDialog, setErrorDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [createdEssay, setCreatedEssay] = useState<any>();
  const [newThemeDialog, setNewThemeDialog] = useState(false);
  const [newThemeDialogEdit, setNewThemeDialogEdit] = useState(false);
  const [valueTabs, setValueTabs] = useState(0);

  const [textApoio, setTextApoio] = useState("");
  const [idTextApoio, setIdApoio] = useState(0);
  const [eventCharcode, setEventCharcode] = useState(0);
  const [inputFields, setInputFields] = useState([{ text: "" }]);
  const [inputFieldsUpdate, setInputFieldsUpdate] = useState([
    { motivatingTextId: idTextApoio, text: textApoio },
  ]);

  const [addTheme, setAddTheme] = useState(false);
  const [editTheme, setEditTheme] = useState(false);
  const [deleteTheme, setDeleteTheme] = useState(false);
  const [selectTheme, setSelectTheme] = useState({});
  const [nTitle, setNTitle] = useState();
  const [nDescription, setNDescription] = useState("");
  const [nHelpText, setNHelpText] = useState("");
  const [open, setOpen] = useState(false);
  const themes = useTheme();
  const fullScreen = useMediaQuery(themes.breakpoints.down("md"));
  const [themeStatus, setThemeStatus] = useState(0);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ script: "sub" }, { script: "super" }],
      [{ color: [] }, { background: [] }],
    ],
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const init = async () => {
      if (theme.length >= 4) {
        apiSecure
          .get("Theme/MotivatingTexts?themeId=" + theme)
          .then(function (response) {
            setInputFieldsUpdate(response.data);
            setIdApoio(response.data.id);
            setTextApoio(response.data.text);
          })
          .catch(function (error) {});
      }
    };
    init();
  }, [inputFieldsUpdate, theme]);

  useEffect(() => {
    if (type > 0 && gender > 0) {
      getReasons();
    }

    //console.log(owner);

    //Aqui...

    function getReasons() {
      const label = "Não há temas disponíveis";
      apiSecure
        .get("/Theme/type/" + type + "/genre/" + gender + "/owner/" + owner)
        .then(function (response) {
          setThemeStatus(response.status);
          if (response.status === 204) {
            label as string;
          }
        })
        .catch(function (error) {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, gender, request]);

  useEffect(() => {
    if (addTheme === true) {
      apiSecure
        .post("/Theme", {
          Title: nTitle,
          Description: nDescription,
          themeGenderId: gender,
          themeTypeId: type,
          motivatingTexts: inputFields,
        })
        .then(function (response) {
          //console.log(response);
          setAddTheme(false);
          setNewThemeDialog(false);
        })
        .catch(function (error) {
          //console.error(error);
          setAddTheme(false);
          setNewThemeDialog(false);
        });
    } else if (editTheme === true) {
      apiSecure
        .put("/Theme/" + theme + "/themeUpdate", {
          title: nTitle,
          description: nDescription,
          themeGenreId: gender,
          themeTypeId: type,
          motivatingTexts: inputFieldsUpdate,
        })
        .then(function (response) {
          //console.log(response);
          setAddTheme(false);
          setNewThemeDialog(false);
        })
        .catch(function (error) {
          //console.error(error);
          setAddTheme(false);
          setNewThemeDialog(false);
        });
    } else if (deleteTheme === true) {
      apiSecure
        .delete("/theme/" + theme)
        .then(function (response) {
          setEssayDetails(response.data.description);
          setNTitle(response.data.title);
          setNDescription(response.data.description);
        })
        .catch(function (error) {
          //console.error(error);
        });
    }
  }, [
    addTheme,
    deleteTheme,
    editTheme,
    gender,
    inputFields,
    inputFieldsUpdate,
    nDescription,
    nTitle,
    theme,
    type,
  ]);

  const handleAddTheme = () => {
    setAddTheme(true);
    setTimeout(() => {
      cleanList();
    }, 2000);
  };

  const handleDescription = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (eventCharcode) {
      const descriptionTheme = event.target.value.replaceAll(
        `#pp`,
        `         `
      );
      setNDescription(descriptionTheme);
    } else {
      const descriptionTheme = event.target.value;
      setNDescription(descriptionTheme);
    }
  };

  const handleInputChangeText = (
    index: number,
    eventOrValue:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | string
  ) => {
    const inputFieldsValue = [...inputFields];
    let newValue: string;

    if (typeof eventOrValue === "string") {
      newValue = eventOrValue;
    } else {
      newValue = eventOrValue.target.value;
    }

    if (eventCharcode) {
      inputFieldsValue[index].text = newValue.replaceAll(`#pp`, `         `);
    } else {
      inputFieldsValue[index].text = newValue;
    }

    setInputFields(inputFieldsValue);
  };

  const handleInputChangeEdit = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputFieldsValueUpdate = [...inputFieldsUpdate];

    if (eventCharcode) {
      inputFieldsValueUpdate[index].text = event.target.value.replaceAll(
        `#pp`,
        `         `
      );
      setInputFieldsUpdate(inputFieldsValueUpdate);
    } else {
      inputFieldsValueUpdate[index].text = event.target.value;
      setInputFieldsUpdate(inputFieldsValueUpdate);
    }
  };

  const handleKeyPress = (
    event: KeyboardEvent<HTMLImageElement | HTMLTextAreaElement>
  ) => {
    setEventCharcode(event.charCode);
  };

  const handleChange = (_event: React.ChangeEvent<{}>, value: number) => {
    setValueTabs(value);
  };

  const handleAddFields = () => {
    const values: any = [...inputFields];
    //console.log(values);
    values.push({ text: "" });
    setInputFields(values);
  };

  const handleEditTheme = () => {
    setEditTheme(true);
    setNewThemeDialogEdit(false);
    setTimeout(() => {
      cleanList();
    }, 2000);
  };

  const handleDeleteTheme = () => {
    setNewThemeDialogEdit(false);
    setDeleteTheme(true);
    setOpen(false);
    setTimeout(() => {
      cleanList();
    }, 2000);
    Refresh();
  };

  function Refresh() {
    window.location.href = window.location.href;
  }

  const handleRemoveFields = (index: any) => {
    const values = [...inputFields];
    if (values.length > 1) {
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  function onSubmitTheme(formState: any) {
    setTheme(formState);

    apiSecure
      .get("/theme/" + formState)
      .then(function (response) {
        setEssayDetails(response.data.description);
        setNTitle(response.data.title);
        setNDescription(response.data.description);
        setSelectTheme(response.data);
      })
      .catch(function (error) {
        //console.error(error);
      });

    apiSecure
      .get("Theme/MotivatingTexts?themeId=" + formState)
      .then(function (response) {
        setInputFieldsUpdate(response.data);
      })
      .catch(function (error) {
        //console.error(error);
      });
  }
  function onSubmitType(formState: any) {
    setType(formState);
  }

  function onSubmitGender(formState: any) {
    setGender(formState);
  }

  function onSubmitSchool(formState: any) {
    var schoolsList: any = [];
    formState.map((e: any) => {
      schoolsList.push(e.schoolId);
      return e;
    });
    setSchool(schoolsList);
    //console.log(schoolsList);
  }

  function onChangeClassrooms(formState: any) {
    var classroomsList: any = [];
    formState.map((e: any) => {
      classroomsList.push(e.id);
      return e;
    });
    setSelectedClassrooms(classroomsList);
  }

  function maxWordsSet(value: any) {
    if (value <= 150) {
      setMaxWords(150);
    } else {
      setMaxWords(value);
    }
  }

  function minWordsSet(value: any) {
    setMinWords(value);
  }

  useEffect(() => {
    if (school.length > 0) {
      apiSecure
        .get("/TeacherSchool/classes", {
          params: {
            schoolids: school,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then(function (response) {
          setClassrooms(response.data);
        })
        .catch(function (error) {
          //console.error(error);
        });
    }
  }, [qs, school]);

  useEffect(() => {
    if (send === true && selectTheme) {
      apiSecure
        .post("/Essay/Teacher", {
          themeId: theme,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          details: essayDetails,
          classroomIds: selectedClassroom,
          minAmountWords: Number(minWords),
          maxAmountWords: Number(maxWords),
        })
        .then(function (response) {
          setCreatedEssay(response.data);
          setOpenDialog(true);
          setSend(false);
        })
        .catch(function (error) {
          //console.error(error);
          setSend(false);
        });
    } else {
      setErrorDialog(send);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    endDate,
    essayDetails,
    maxWords,
    minWords,
    selectedClassroom,
    send,
    startDate,
    theme,
  ]);

  useEffect(() => {
    //console.log(startDate);
  }, [startDate]);

  function reloadListFull() {
    setRequest(false);
    setOwner(false);
  }

  function reloadListOwner() {
    setRequest(true);
    setOwner(true);
  }

  function cleanList() {
    history.push("/teacher/essay/new");
  }

  return (
    <>
      <Topbar profile="10" menu="home" />
      <div
        style={{
          textAlign: "center",
          margin: "1% 1% 0 1%",
          width: "97vw",
          backgroundColor: "var(--blue-light)",
          padding: "1%",
          borderRadius: "0.25rem",
        }}
      >
        <h1 style={{ color: "var(--white)" }}>PROPOR REDAÇÃO</h1>
      </div>
      <Container>
        <Content className={"card"}>
          <Box
            sx={{
              marginBottom: "1.5%",
            }}
          >
            <Tabs
              centered
              variant="fullWidth"
              value={valueTabs}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="basic tabs example"
            >
              <Tab
                label="TEMAS DA PLATAFORMA"
                onClick={reloadListFull}
                {...a11yProps(0)}
              />
              <Tab
                label="USAR MEUS TEMAS"
                onClick={reloadListOwner}
                {...a11yProps(1)}
              />
              <Tab
                label="CRIAR MEUS TEMAS"
                onClick={() => setNewThemeDialog(true)}
              />
              <Tab
                disabled={owner === false || !nTitle || themeStatus === 204}
                label="EDITAR MEUS TEMAS"
                onClick={() => setNewThemeDialogEdit(true)}
              />
            </Tabs>
          </Box>

          <div style={{ marginTop: "3%" }}></div>
          <ThemesType onSubmit={onSubmitType} requestList={request} />

          <ThemesGenre
            onSubmit={onSubmitGender}
            typeId={type}
            requestList={request}
          />

          {gender > 0 && (
            <ThemesAutocomplete
              onSubmit={onSubmitTheme}
              type={type}
              gender={gender}
              owner={owner}
              requestList={request}
            />
          )}

          {/* <Typography className={'typography'}>Número de palavras:</Typography> */}
          <TextField
            type={"number"}
            value={minWords}
            label="Mínimo de palavras"
            className={"inputsClasses"}
            variant="outlined"
            onChange={(e) => minWordsSet(e.target.value)}
          />
          <TextField
            type={"number"}
            value={maxWords}
            label="Máximo de palavras"
            variant="outlined"
            onChange={(e) => maxWordsSet(e.target.value)}
            className={"inputsClasses"}
          />
          <TextField
            id="dateStart"
            label="Data da proposta"
            type="date"
            variant="outlined"
            defaultValue={moment().format("YYYY-MM-DD")}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            className={"inputsClasses"}
          />
          <TextField
            id="dateEnd"
            label="Data de finalização"
            type="date"
            variant="outlined"
            className={"inputsClasses inputLast"}
            defaultValue={moment().add(14, "day").format("YYYY-MM-DD")}
            onChange={(e) => {
              //console.log(moment(e.target.value).format("YYYY-MM-DD"));
              return setEndDate(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button
            style={{
              color: "var(--white)",
              backgroundColor: "var(--blue-light)",
              padding: "2%",
              height: "2.5rem",
            }}
            onClick={() => cleanList()}
          >
            Limpar campos
          </Button>
        </Content>

        <Content className={"card"}>
          <Schools onSubmit={onSubmitSchool} className={"inputs"} />
          <Autocomplete
            multiple
            id="turmas"
            options={classrooms}
            onChange={(event, newValue) => onChangeClassrooms(newValue)}
            getOptionLabel={(option: any) =>
              option.description + option.schoolDescription
            }
            renderInput={(params) => (
              <TextField {...params} label="Turmas" variant="outlined" />
            )}
            className={"inputsClasses inputLast"}
          />
          <Quill
            value={essayDetails}
            placeholder="Proposta"
            onChange={(content, delta, source, editor) => {
              const newValue = editor.getHTML();
              setEssayDetails(newValue);
            }}
            className={"ql-editor"}
            modules={modules}
          ></Quill>
          {/* <textarea
            value={essayDetails}
            placeholder="Proposta"
            onChange={(e) => setEssayDetails(e.target.value)}
            className={"text"}
            style={{}}
          /> */}
          <button
            onClick={() => setSend(true)}
            className={"button"}
            disabled={essayDetails.length == 0 ? true : false}
          >
            ENVIAR
          </button>
        </Content>
      </Container>

      {inputFieldsUpdate &&
        inputFieldsUpdate.length > 0 &&
        essayDetails.length > 0 && (
          <>
            <div
              style={{
                textAlign: "center",
                margin: "0 1% 0 1%",
                backgroundColor: "var(--blue-light)",
                padding: "1%",
                borderTopLeftRadius: "0.25rem",
                borderTopRightRadius: "0.25rem",
              }}
            >
              <h2 style={{ color: "var(--white)" }}>TEXTOS DE APOIO</h2>
            </div>
            <Texts style={{ marginBottom: "1%" }}>
              {inputFieldsUpdate &&
                inputFieldsUpdate.map((e: any) => (
                  <>
                    {e.isFile && (
                      <img
                        alt=""
                        style={{
                          margin: "3rem",
                        }}
                        src={e.text}
                      />
                    )}
                    {!e.isFile && (
                      <div
                        style={{ textAlign: "justify", textIndent: 50 }}
                        dangerouslySetInnerHTML={{
                          __html: e.text,
                        }}
                      ></div>
                    )}
                    <Divider style={{ margin: 30 }} />
                  </>
                ))}
            </Texts>
          </>
        )}

      <Dialog
        open={errorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"ATENÇÃO: Tema não selecionado"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Selecione o tema antes de enviar a Redação. Preencha todos os campos
            antes de enviar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setSend(false)}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ color: "var(--blue-light)" }}
        >
          {"REDACÃO PROPOSTA COM SUCESSO!"}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Redação proposta para {createdEssay?.studentsAdded} alunos
            <p>Compartilhe com seus alunos o link:</p>
            https://redacao.pr.gov.br/essay/students/{createdEssay?.token}
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  'https://redacao.pr.gov.br/essay/students/' +
                    createdEssay?.token
                )
              }}
              color={'primary'}
            >
              COPIAR LINK
            </Button>
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Link to={"/teacher/home"}>
            <Button color="primary">Voltar</Button>
          </Link>
        </DialogActions>
      </Dialog>

      <Dialog
        open={newThemeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        onClose={() => setNewThemeDialog(false)}
      >
        <div style={{ backgroundColor: "var(--blue-light)" }}>
          <center>
            <DialogTitle
              id="alert-dialog-title"
              style={{ color: "var(--white)" }}
            >
              {"CADASTRO DE NOVOS TEMAS"}
            </DialogTitle>
          </center>
        </div>
        <Box style={{ margin: "1%" }}>
          <center>
            <DialogContentText id="alert-dialog-description">
              O tema não ficará visivel para outros professores.
            </DialogContentText>
          </center>
        </Box>
        <DialogContent>
          <ThemesType onSubmit={onSubmitType} requestList={request} />

          <ThemesGenre
            onSubmit={onSubmitGender}
            typeId={type}
            requestList={request}
          />
          {/* <ThemesType className={"inputs"} onSubmit={onSubmitType} />
          <ThemesGenre onSubmit={onSubmitGender} typeId={0} /> */}
          <TextField
            label={"Título da proposta"}
            fullWidth
            className={"inputs"}
            variant="outlined"
            onChange={(e: any) => setNTitle(e.target.value)}
            required
            error={nTitle === null || nTitle === "undefined" || nTitle === ""}
            style={{ marginBottom: 15 }}
          />
          {/* QUILL NOVO TEMA */}
          <DialogContentText id="alert-dialog-description">
            DESCRIÇÃO DA PROPOSTA *
          </DialogContentText>
          <Quill
            value={nDescription}
            placeholder="Descrição da proposta *"
            onChange={(content, delta, source, editor) => {
              const newValue = editor.getHTML();
              setNDescription(newValue);
            }}
            className="ql-editor"
            modules={modules}
            style={{
              margin: "3% 0px",
              padding: "0px",
              borderRadius: "0.25rem",
              height: "200px",
            }}
          ></Quill>
          {/* <TextField
            label={"Descrição da proposta"}
            fullWidth
            className={"inputs"}
            style={{ marginBottom: 15 }}
            variant="outlined"
            value={nDescription}
            multiline
            onChange={(e) => handleDescription(e)}
            //onCopy={(e) => e.preventDefault()}
            //onPaste={(e) => e.preventDefault()}
            onKeyPress={(e: KeyboardEvent<HTMLImageElement>) =>
              handleKeyPress(e)
            }
            required
            error={
              nDescription == null ||
              nDescription === "undefined" ||
              nDescription === ""
            }
            rows={10}
          /> */}
          <DialogContentText id="alert-dialog-description">
            TEXTOS DE APOIO *
          </DialogContentText>
          {inputFields.map((inputField: any, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <div className="form-group col-sm-6">
                <Quill
                  value={inputField.text}
                  placeholder="Texto de apoio *"
                  onChange={(content, delta, source, editor) => {
                    const newValue = editor.getHTML();
                    handleInputChangeText(index, newValue);
                  }}
                  className="ql-editor"
                  modules={modules}
                  style={{
                    width: "100%",
                    marginTop: 6,
                    margin: "3% 0px",
                    padding: "0px",
                    borderRadius: "0.25rem",
                    height: "200px",
                  }}
                ></Quill>

                {/* <TextField
                  type="text"
                  label={"Texto de apoio"}
                  required
                  className={"inputs"}
                  id="firstName"
                  name="firstName"
                  variant={"outlined"}
                  value={inputField.text}
                  multiline
                  style={{ width: "100%", marginTop: 6 }}
                  rowsMax={20}
                  onChange={(event) => handleInputChangeText(index, event)}
                  //onCopy={(e) => e.preventDefault()}
                  //onPaste={(e) => e.preventDefault()}
                  onKeyPress={(e: KeyboardEvent<HTMLImageElement>) =>
                    handleKeyPress(e)
                  }
                /> */}
              </div>
              <div className="form-group col-sm-2">
                {inputFields.length > 1 && (
                  <Button
                    className="btn btn-link"
                    type="button"
                    style={{ color: "red" }}
                    onClick={() => handleRemoveFields(index)}
                  >
                    Remover Texto de apoio
                  </Button>
                )}
                <Button
                  className="btn btn-link"
                  type="button"
                  style={{ color: "green" }}
                  onClick={() => handleAddFields()}
                >
                  Adicionar Texto de apoio
                </Button>
              </div>
            </Fragment>
          ))}
        </DialogContent>

        <DialogActions style={{ marginRight: 8 }}>
          <Button
            style={{ color: "var(--red)" }}
            onClick={() => setNewThemeDialog(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: "var(--blue-light)" }}
            onClick={handleAddTheme}
            disabled={!nTitle || !nDescription}
          >
            Adicionar Tema
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={newThemeDialogEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        onClose={() => setNewThemeDialogEdit(false)}
      >
        <div style={{ backgroundColor: "var(--blue-light)" }}>
          <center>
            <DialogTitle
              id="alert-dialog-title"
              style={{ color: "var(--white)" }}
            >
              {"EDITAR MEUS TEMAS"}
            </DialogTitle>
          </center>
        </div>
        <Box style={{ margin: "1%" }}>&nbsp;</Box>
        <DialogContent>
          <TextField
            value={nTitle}
            label={"Título da proposta"}
            fullWidth
            className={"inputs"}
            variant="outlined"
            onChange={(e: any) => setNTitle(e.target.value)}
            required
            error={nTitle === null || nTitle === "undefined" || nTitle === ""}
            style={{ marginBottom: "4%" }}
          />
          <Quill
            value={nDescription}
            placeholder="Descrição da proposta *"
            onChange={(content, delta, source, editor) => {
              const newValue = editor.getHTML();
              setNDescription(newValue);
            }}
            className="ql-editor"
            modules={modules}
            style={{
              margin: "2% 0px",
              padding: "0px",
              borderRadius: "0.25rem",
              height: "200px",
            }}
          ></Quill>
          {/* <TextField
            value={nDescription}
            label={"Descrição da proposta"}
            fullWidth
            error={
              nDescription == null ||
              nDescription === "undefined" ||
              nDescription === ""
            }
            multiline
            required
            className={"inputs"}
            variant="outlined"
            onChange={(e) => handleDescription(e)}
            //onCopy={(e) => e.preventDefault()}
            //onPaste={(e) => e.preventDefault()}
            onKeyPress={(e: KeyboardEvent<HTMLImageElement>) =>
              handleKeyPress(e)
            }
            style={{ marginBottom: "4%" }}
            rows={10}
          /> */}
          {inputFieldsUpdate.map((inputFieldUpdate, index) => (
            <Fragment key={`${inputFieldUpdate}~${index}`}>
              <div
                style={{ marginBottom: "4%" }}
                className="form-group col-sm-6"
              >
                <TextField
                  type="text"
                  label={"Texto de apoio"}
                  required
                  className={"inputs"}
                  id="firstName"
                  name="firstName"
                  variant={"outlined"}
                  value={inputFieldUpdate.text}
                  style={{ width: "100%" }}
                  multiline
                  rowsMax={20}
                  onChange={(event) => handleInputChangeEdit(index, event)}
                  onKeyPress={(e: KeyboardEvent<HTMLImageElement>) =>
                    handleKeyPress(e)
                  }
                />
              </div>
            </Fragment>
          ))}
        </DialogContent>

        <DialogActions
          style={{ margin: "1%", width: "98%", overflow: "hidden" }}
        >
          <Button
            style={{
              color: "var(--red)",
              borderWidth: "2",
              textAlign: "left",
              marginRight: "42%",
            }}
            onClick={() => setOpen(true)}
            disabled={!nTitle || !nDescription}
          >
            Deletar Tema
          </Button>
          <Button
            style={{ color: "var(--blue-light)" }}
            onClick={() => setNewThemeDialogEdit(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: "var(--blue-light)" }}
            onClick={() => handleEditTheme()}
            disabled={!nTitle || !nDescription}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ color: "var(--blue-light)" }}>
          {"DESEJA DELETAR SEU TEMA?"}
        </DialogTitle>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button style={{ color: "var(--red)" }} onClick={handleDeleteTheme}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default NewEssay;
