import api from "../api";
import axios from "axios";

export const isAuthenticated = () =>
	localStorage.getItem("Token") !== null &&
	localStorage.getItem("RefreshToken") !== null;

export const getProfileId = () => Number(localStorage.getItem("ProfileId"));

export function getOut() {
	return (window.top.location.href = "/login");
}

let isAlreadyFetchingAccessToken = false;

let subscribers = [];

export async function ResetTokenAndReattemptRequest(error) {
	try {
		const { response: errorResponse } = error;
		const retryOriginalRequest = new Promise((resolve) => {
			addSubscriber((access_token) => {
				errorResponse.config.headers.Authorization = "Bearer " + access_token;
				resolve(axios(errorResponse.config));
			});
		});
		if (!isAlreadyFetchingAccessToken) {
			isAlreadyFetchingAccessToken = true;
			await api
				.post("/Auth/refresh", {
					Token: localStorage.getItem("RefreshToken"),
					LoginProvider: "Web",
				})
				.then(function (response) {
					localStorage.setItem("Token", response.data.accessToken);
					localStorage.setItem("RefreshToken", response.data.refreshToken);
					localStorage.setItem("ExpiresAt", response.data.expiresAt);
					localStorage.setItem("ProfileId", response.data.profileId);
					localStorage.setItem("IsReadOnly", response.data.readOnly);
				})
				.catch(function (error) {
					return Promise.reject(error);
				});
			isAlreadyFetchingAccessToken = false;
			onAccessTokenFetched(localStorage.getItem("Token"));
		}
		return retryOriginalRequest;
	} catch (err) {
		return Promise.reject(err);
	}
}

function onAccessTokenFetched(access_token) {
	subscribers.forEach((callback) => callback(access_token));
	subscribers = [];
}

function addSubscriber(callback) {
	subscribers.push(callback);
}
